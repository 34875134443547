@import url('https://fonts.googleapis.com/css?family=Lato:400,700|Inter:400,600&display=swap');

// Custom Theming for NG-ZORRO
// For more information: https://ng.ant.design/docs/customize-theme/en
@import '../node_modules/ng-zorro-antd/ng-zorro-antd.less';
@import '@angular/cdk/a11y-prebuilt.css';

@import url(./styles/text-style.less);
@import url(./styles/modal.less);
// Override less variables to here
// View all variables: https://github.com/NG-ZORRO/ng-zorro-antd/blob/master/components/style/themes/default.less

@keyframes spinning {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.spinning-el {
  animation: spinning 1s linear infinite;
}

.default-loader-with-icon {
  width: 100%;
  padding: 4px 16px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.normalize-button {
  border: none;
  outline: none;
  padding: 0;
  margin: 0;
  background: transparent;
  display: block;
  cursor: pointer;
  text-align: initial;
  user-select: none;

  &:disabled {
    cursor: initial;
  }

  &.with-hover {
    @media (hover: hover) {
      &:hover {
        border-radius: 8px;
        background: #E2E5EB;
      }
    }

    @media (hover: none) {
      &:active {
        border-radius: 8px;
        background: #E2E5EB;
      }
    }


    &:active {
      background: #E2E5EB;
    }
  }

  &.with-hover-effect {
    width: 32px;
    height: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 8px;

    @media (hover: hover) {
      &:hover {
        border-radius: 8px;
        background: #E2E5EB;
      }
    }

    @media (hover: none) {
      &:active {
        border-radius: 8px;
        background: #E2E5EB;
      }
    }

    &:active {
      background: #E2E5EB;
    }

    &.disabled {
      background: #E2E5EB;
      user-select: none;
      cursor: not-allowed;
    }
  }

  &.blackwhite-icon-button {
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 8px;
    color: #0E1217;

    &.active {
      background: #0E1217;
      color: #FFFFFF;
    }
  }
}

.ant-dropdown-menu-sub {
  padding: 0;
  margin: 0;
  border: none;
  outline: none;
  background: transparent;
  min-width: 160px;
}

.ant-modal-content {
  border-radius: 16px !important;
}
// Color
@primary-color: #014fcf;
@error-color: #eb4101;
@input-placeholder-color: #57738b;
@border-color-base: #acbdcc;
@avatar-bg: #014fcf;
@layout-header-background: #ffffff;
@layout-header-height: 66px;
@layout-header-padding: 16px;
@nz-spin-bg: rgba(0, 0, 0, 0.2);

// Font
@font-family:
  'Lato',
  'Inter',
  -apple-system,
  BlinkMacSystemFont,
  'Segoe UI',
  Roboto,
  'Helvetica Neue',
  Arial,
  'Noto Sans',
  sans-serif,
  'Apple Color Emoji',
  'Segoe UI Emoji',
  'Segoe UI Symbol',
  'Noto Color Emoji';
@font-size-base: 16px;

// Size
@border-radius-base: 4px;
@height-base: 48px;
@height-sm: 40px;
@dropdown-line-height: 32px;

.ant-form-item-control-input {
  width: inherit;
  align-items: flex-start;
}

.ant-form-item-explain-error {
  font-family: Manrope;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.01em;
}

* {
  background-color: almost-white;
}

nz-form-control {
  align-items: flex-start !important;
}

input {
  max-height: 48px !important;
  border-radius: 4px !important;
  font-family: Manrope;
  font-style: normal;
  font-weight: 400;
  font-size: 16px !important;
  line-height: 24px;
  letter-spacing: 0.01em;
}

/* You can add global styles to this file, and also import other style files */

* {
  font-family: 'Manrope';
  font-size: 16px;
  -webkit-font-smoothing: antialiased;
}

// Route Animation

:ng-deep .ant-popover-inner-content,
:ng-deep .ant-popover-inner,
.custom-popover {
  background-color: #014fcf;
  //styleName: reg M : 14R;
  font-family: Manrope;
  font-size: 14px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0.01em;
  text-align: left;
  font-variation-settings: 'slnt' 0;
  color: #eaeff3;
}


.cdk-overlay-container .ant-dropdown ul {
  padding: 4px;
}

body {
  position: fixed;
  font-feature-settings: initial;
  font-variant: initial;
}

.mobile-visible {
  display: none;
}

@media (max-width: 640px) {
  .mobile-hidden {
    display: none !important;
  }

  .mobile-visible {
    display: block;
  }
}

@media (min-width: 640px) {
  .dekstop-hidden {
    display: none !important;
  }
}
