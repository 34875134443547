.title-xxxl {
  font-family: Manrope;
  font-style: normal;
  font-weight: 600;
  font-size: 32px;
  line-height: 40px;
  letter-spacing: 0.01em;
  color: #010B22;
}

.title-xxl {
  font-family: Manrope;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 32px; /* 133.333% */
  letter-spacing: 0.24px;
  color: #010B22;
}

.title-xl {
  color: #010B22;
  font-family: Manrope;
  font-style: normal;
  font-weight: 700;
  font-size: 19px;
  line-height: 23px;
}

.title-l {
  font-family: Manrope;
  font-style: normal;
  font-weight: 700;
  font-size: 17px;
  line-height: 20px;
  color: #c4c4c4;
  background: #e5e5e5;
}

.title-m {
  font-family: Manrope;
  font-style: normal;
  font-weight: 700;
  font-size: 15px;
  line-height: 18px;
  color: #c4c4c4;
  background: #e5e5e5;
}

.title-s {
  font-family: Manrope;
  font-style: normal;
  font-weight: 700;
  font-size: 13px;
  line-height: 16px;
  color: #c4c4c4;
}

.title-xs {
  font-family: Manrope;
  font-style: normal;
  font-weight: 700;
  font-size: 11px;
  line-height: 13px;
  color: #c4c4c4;
}

.reg-xxxl {
  font-family: Manrope;
  font-style: normal;
  font-weight: 400;
  font-size: 46px;
  line-height: 55px;
  color: #c4c4c4;
  background: #e5e5e5;
}

.reg-xxl {
  color: #010B22;
  font-family: Manrope;
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 29px;
}

.reg-xl {
  color: #c4c4c4;
  font-family: Manrope;
  font-style: normal;
  font-weight: 400;
  font-size: 19px;
  line-height: 23px;
}

.reg-l {
  color: #010B22;
  font-family: Manrope;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.01em;
}

.reg-m {
  color: #010B22;
  font-family: Manrope;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 142.857% */
  letter-spacing: 0.14px;
}

.reg-s {
  font-family: Manrope;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px; /* 133.333% */
  letter-spacing: 0.12px;
  color: #010B22;
}

.reg-xs {
  color: #c4c4c4;
  font-family: Manrope;
  font-style: normal;
  font-weight: 400;
  font-size: 11px;
  line-height: 13px;
}

.bold-s {
  color: var(--monochrome-black, #010B22);
  font-family: Manrope;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px; /* 114.286% */
  letter-spacing: 0.14px;
}

.bold-m {
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px; /* 150% */
  letter-spacing: 0.16px;
}

.semi-bold-xs {
  color: var(--monochrome-black, #010B22);
  font-family: Manrope;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px; /* 133.333% */
  letter-spacing: 0.12px;
}

.label-sm {
  font-family: Manrope;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px; /* 142.857% */
}

.label-m {
  font-family: Manrope;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px; /* 150% */
}

.label-x-m {
  color: var(--Monochrome-950, #0E1217);
  font-family: Manrope;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px; /* 150% */
}

.paragraph-sm {
  color: var(--monochrome-black, #010B22);
  font-family: Manrope;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; /* 142.857% */
}

.paragraph-m {
  color: var(--monochrome-black, #010B22);
  font-family: Manrope;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px; /* 150% */
}

.heading-sm {
  color: #010B22;
  font-family: Manrope;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 32px; /* 133.333% */
}

.heading-m {
  color: #010B22;
  font-family: Manrope;
  font-size: 28px;
  font-style: normal;
  font-weight: 600;
  line-height: 36px; /* 128.571% */
}

.paragraph-x-sm {
  color: #010B22;
  font-family: Manrope;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px; /* 133.333% */
}

.heading-x-sm {
  font-family: Manrope;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 28px; /* 140% */
}

.heading-xx-sm {
  font-family: Manrope;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px; /* 150% */
}

.heading-xxx-sm {
  font-family: Manrope;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px; /* 142.857% */
}

.label-x-sm {
  font-family: Manrope;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 16px; /* 133.333% */
}
